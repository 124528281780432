// import services.js
import api from '@/api/services.js'

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => 
{
  if(to.matched.some(route => route.meta.requiresAuth))
  {
    if(localStorage.getItem('authUser') != undefined)
    {
      // se realiza la solicitud para verificar que el token sea valido
      const resp = await api.getClientes(JSON.parse(localStorage.getItem('authUser')))
      
      if (typeof(resp.message) != 'undefined' && resp.message.includes('TypeError') == true) {
        next({ path: '/', query: { redirect: to.fullPath } });
      }
      else
      {
        next()
      }
    }else 
      next({ path: '/', query: { redirect: to.fullPath } });

  }else
    next()
})
export default router;
