<template>
	<div>
		<b-dropdown-header class="noti-title">
			<h6 class="text-overflow m-0">Bienvenido</h6>
		</b-dropdown-header>
		<b-dropdown-item
			v-for="(link, index) in links"
			:key="index"
			@click="redirect(link.link)"
		>
			<i :class="link.icon"></i>
			<span>{{ link.name }}</span>
		</b-dropdown-item>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				links: [
					{
						link: 'CambiarContra',
						name: 'Cambiar Contraseña',
						icon: 'ni ni-settings-gear-65',
					},
					{ link: 'Logout', name: 'Cerrar sesión', icon: 'ni ni-user-run'},
				],
			};
		},
		methods: {
			redirect(url) {
				this.$router.push({ name: url });
			},
		},
	};
</script>
