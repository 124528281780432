<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

	export default {
		methods: {
				...mapActions({
				setAuthUser: 'setAuthUser',
			}),

		},
		mounted() {
			if(localStorage.getItem('authUser') != undefined)
			{
				this.setAuthUser(JSON.parse(localStorage.getItem('authUser')))
			}
		}

	}
</script>
