import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () =>
	import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () =>
	import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () =>
	import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () =>
	import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () =>
	import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () =>
	import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () =>
	import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const DashboardEncuestas = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/DashboardEncuestas.vue');
const DashboardPromos = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/DashboardPromos.vue');
const AgregarTicket = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AgregarTicket.vue');
const AgregarFactura = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AgregarFactura.vue');
const ScanBono = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ScanBono.vue');
const ListadoTickets = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoTickets.vue');
const ListadoFacturas = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoFacturas.vue');
	const ListadoMarcas = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoMarcas.vue');
const ListadoClientes = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoClientes.vue');
const ListadoPlanes = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoPlanes.vue');
const ListadoLicencias = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoLicencias.vue');
const ListadoPartidos = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoPartidos.vue');	
const ListadoMantenimiento = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoMantenimiento.vue');		
const ListadoUsuarios = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ListadoUsuarios.vue');		
const ScanVending = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/ScanVending.vue');
const PromocionesCliente = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/PromocionesCliente.vue');
const BonosCliente = () =>
	import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/BonosCliente.vue');
const AlternativeDashboard = () =>
	import(
		/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue'
	);
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const ListPromos = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/ListPromos.vue');
const ListBonos = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/ListBonos.vue');
const FormEncuestas = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/FormEncuestas.vue');
const FormPromociones = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/formPromociones.vue');
const FormBonos = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/formBonos.vue');
const FormComponents = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () =>
	import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () =>
	import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () =>
	import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Logout = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Logout.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');
const ControlUsuarios = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/ControlUsuarios.vue');
const CambiarContra = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/CambiarContra.vue');
const Ayuda = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Ayuda.vue');
const Perfil = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Perfil.vue');
const Configuracion = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/Configuracion.vue');
const AltaUsuario = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/AltaUsuario.vue');
const AltaGestoria = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/AltaGestoria.vue');
const IotCliente = () =>
	import(/* webpackChunkName: "pages" */ '@/views/Pages/IotCliente.vue');

// TableList pages
const RegularTables = () =>
	import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () =>
	import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () =>
	import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');
let componentsMenu = {
	path: '/components',
	component: DashboardLayout,
	redirect: '/components/buttons',
	name: 'Components',
	children: [
		{
			path: 'buttons',
			name: 'Buttons',
			component: Buttons,
		},
		{
			path: 'cards',
			name: 'Cards',
			component: Cards,
		},
		{
			path: 'grid-system',
			name: 'Grid System',
			component: GridSystem,
		},
		{
			path: 'notifications',
			name: 'Notifications',
			component: Notifications,
		},
		{
			path: 'icons',
			name: 'Icons',
			component: Icons,
		},
		{
			path: 'typography',
			name: 'Typography',
			component: Typography,
		},
	],
};
let formsMenu = {
	path: '/forms',
	component: DashboardLayout,
	redirect: '/forms/elements',
	name: 'Forms',
	children: [
		{
			path: 'elements',
			name: 'Form elements',
			component: FormElements,
		},
		{
			path: 'listPromos',
			name: 'listPromos',
			component: ListPromos,
		},
		{
			path: 'listBonos',
			name: 'listBonos',
			component: ListBonos,
		},
		{
			path: 'encuestas',
			name: 'Formulario encuestas',
			component: FormEncuestas,
		},
		{
			path: 'promociones',
			name: 'Formulario promociones',
			component: FormPromociones,
		},
		{
			path: 'bonos',
			name: 'Formulario bonos',
			component: FormBonos,
		},
		{
			path: 'components',
			name: 'Form components',
			component: FormComponents,
		},
		{
			path: 'validation',
			name: 'Form validation',
			component: FormValidation,
		},
	],
};

let tablesMenu = {
	path: '/tables',
	component: DashboardLayout,
	redirect: '/table/regular',
	name: 'Tables menu',
	children: [
		{
			path: 'regular',
			name: 'Tables',
			component: RegularTables,
		},
		{
			path: 'sortable',
			name: 'Sortable',
			component: SortableTables,
		},
		{
			path: 'paginated',
			name: 'Paginated Tables',
			component: PaginatedTables,
		},
	],
};

let mapsMenu = {
	path: '/maps',
	component: DashboardLayout,
	name: 'Maps',
	redirect: '/maps/google',
	children: [
		{
			path: 'google',
			name: 'Google Maps',
			component: GoogleMaps,
		},
		{
			path: 'vector',
			name: 'Vector Map',
			component: VectorMaps,
		},
	],
};

let pagesMenu = {
	path: '/pages',
	component: DashboardLayout,
	name: 'Pages',
	redirect: '/pages/user',
	children: [
		{
			path: 'user',
			name: 'User Page',
			component: User,
		},
		{
			path: 'timeline',
			name: 'Timeline Page',
			component: TimeLine,
		},
	],
};

let authPages = {
	path: '/',
	component: AuthLayout,
	name: 'Authentication',
	children: [
		/*{
			path: '/home',
			name: 'Home',
			component: Home,
			meta: {
				noBodyBackground: true,
			},
		},*/
		{
			path: '/login',
			name: 'Login',
			component: Login,
		},
		{
			path: '/logout',
			name: 'Logout',
			component: Logout
		},	
		{
			path: '/register',
			name: 'Register',
			component: Register,
		},
		// {
		// 	path: '/pricing',
		// 	name: 'Pricing',
		// 	component: Pricing,
		// },
		{
			path: '/lock',
			name: 'Lock',
			component: Lock,
		},
		{ path: '*', component: NotFound },
	],
};


const routes = [
	{
		path: '/',
		redirect: '/login',
		name: 'Login',
	},
	componentsMenu,
	formsMenu,
	tablesMenu,
	mapsMenu,
	pagesMenu,
	{
		path: '/',
		name: 'dashboard',
		component: DashboardLayout,
		meta: { requiresAuth: true },
		redirect: '/dashboard',
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: Dashboard,
			},
			{
				path: 'dashboardEncuestas',
				name: 'DashboardEncuestas',
				component: DashboardEncuestas,
			},
			{
				path: 'controlUsuarios',
				name: 'ControlUsuarios',
				component: ControlUsuarios,
			},
			{
				path: 'cambiarContra',
				name: 'CambiarContra',
				component: CambiarContra,
			},
			{
				path: 'ayuda',
				name: 'Ayuda',
				component: Ayuda,
			},
			{
				path: 'perfil',
				name: 'Perfil',
				component: Perfil,
			},
			{
				path: 'configuracion',
				name: 'Configuracion',
				component: Configuracion,
			},
			{
				path: 'altaUsuario',
				name: 'AltaUsuario',
				component: AltaUsuario,
			},
			{
				path: 'altaGestoria',
				name: 'AltaGestoria',
				component: AltaGestoria,
			},
			{
				path: 'iotCliente',
				name: 'IotCliente',
				component: IotCliente,
			},
			{
				path: 'dashboardPromos',
				name: 'DashboardPromos',
				component: DashboardPromos,
			},
			{
				path: 'agregarTicket',
				name: 'agregarTicket',
				component: AgregarTicket,
			},
			{
				path: 'agregarFactura',
				name: 'agregarFactura',
				component: AgregarFactura,
			},
			{
				path: 'scanBono',
				name: 'scanBono',
				component: ScanBono,
			},
			{
				path: 'listadoTickets',
				name: 'listadoTickets',
				component: ListadoTickets,
			},
			{
				path: 'listadoFacturas',
				name: 'listadoFacturas',
				component: ListadoFacturas,
			},
			{
				path: 'listadoClientes',
				name: 'listadoClientes',
				component: ListadoClientes,
			},
			{
				path: 'listadoPlanes',
				name: 'listadoPlanes',
				component: ListadoPlanes,
			},
			{
				path: 'listadoLicencias',
				name: 'listadoLicencias',
				component: ListadoLicencias,
			},
			{
				path: 'listadoPartidos',
				name: 'listadoPartidos',
				component: ListadoPartidos,
			},
			{
				path: 'listadoMantenimiento',
				name: 'listadoMantenimiento',
				component: ListadoMantenimiento,
			},
			{
				path: 'listadoUsuarios',
				name: 'listadoUsuarios',
				component: ListadoUsuarios,
			},
			{
				path: 'promocionesCliente',
				name: 'promocionesCliente',
				component: PromocionesCliente,
			},
			{
				path: 'listadoMarcas',
				name: 'listadoMarcas',
				component: ListadoMarcas,
			},
			{
				path: 'scanVending',
				name: 'scanVending',
				component: ScanVending,
			},
			{
				path: 'bonoscliente',
				name: 'bonoscliente',
				component: BonosCliente,
			},
			{
				path: 'alternative',
				name: 'Alternative',
				component: AlternativeDashboard,
				meta: {
					navbarType: 'light',
				},
			},
			{
				path: 'calendar',
				name: 'Calendar',
				component: Calendar,
			},
			{
				path: 'charts',
				name: 'Charts',
				component: Charts,
			},
			{
				path: 'widgets',
				name: 'Widgets',
				component: Widgets,
			},
			{
				path: '/pricing',
				name: 'Pricing',
				component: Pricing,
			},
		],
	},
	authPages,
];

export default routes;
