<!-- CABECERA SUPERIOR -->
<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
  >
    <b-navbar-nav class="header-component align-items-center ml-md-auto">
      <li class="nav-item prueba d-xl-none">
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            'active': $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <span class="menu-toggle"></span>
          </div>
        </div>
      </li>
      <li class="mr-4">
        <scan-vending />
      </li>
    </b-navbar-nav>
    <b-dropdown size="lg" class="bg-grey option-hide"  variant="link" toggle-class="text-decoration-none" no-caret>
      <template #button-content>
        <div class="W20x bg-grey"><img src="../../../public/ico_menu_notificaciones.svg" class="mxW25x" alt="" style="transform: rotate(180deg);"></div>
      </template>
      <b-dropdown-item @click="exportar('PDF')">Exportar PDF</b-dropdown-item>
      <b-dropdown-item @click="exportar('CSV')">Exportar CSV</b-dropdown-item>
    </b-dropdown>
    <img src="../../../public/sustainaballs-logo.svg" class="navbar-brand-img logo option-show" alt="Sidebar logo">
    <b-navbar-nav class="align-items-center ml-auto ml-md-0 accound_wrapper option-hide">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          {{ usuario }}
        </a>
        <template>
          <opciones-perfil />
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
//import AdministrarUsuarios from '@/components/Menu/AdministrarUsuarios';
import OpcionesPerfil from '@/components/Menu/OpcionesPerfil';
import { BaseNav } from '@/components';
import ScanVending from '@/components/Menu/ScanVendingOption';

export default {
  components: {
    BaseNav,
    ScanVending,
    OpcionesPerfil
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description:
        'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      usuario: null,
    };
  },
  mounted() {
    this.usuario = localStorage.getItem('usuario') || '[]';
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  },
};
</script>
<style scoped>
.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.inputLogin{
  border: none;
  padding:15px;
  background-color: #f4f6fa !important;
}

.borderGrey{
  border-bottom: 1px solid #f1f1f1
}

.bg-grey > button{
  background: #f4f6fa;
  margin-left: 10px;
}

.radius{
  border-radius:5px;
}

.iconBuscador{
  position: absolute;
  right: 10px;
  top:10px;
  max-width: 20px;
}

.inlineBlock{
  display: inline-block;
}

.relative{
  position: relative;
}
.logo {
  display: none;
}

@media (max-width: 768px) { 
  .option-hide {
    display: none;
  }

  .option-show {
    display: block;
  }
}
</style>
