<template slot="links">
	<div>
		<div>
			<img src="../../../public/banner_nivel.png" class="W90 ml5 mb-4" alt="" />
		</div>
		<div
			v-for="(route, index) in filteredLinks"
			:key="index"
			:style="route.opa === '1' ? 'opacity: 0.5' : ''"
		>
			<sidebar-item v-if="showLink(route.condition)" :link="route.link">
				<sidebar-item
					v-for="(subroute, index) in route.subLinks"
					:key="index"
					:link="subroute"
				></sidebar-item>
			</sidebar-item>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';
	export default {
		computed: {
			...mapGetters({
				facturacion: 'facturacion',
				promociones: 'promociones',
				gasto: 'gasto',
				encuestas: 'encuestas',
				gestoria: 'gestoria',
				dashboard: 'dashboard',
				authUser: 'authUser'
			}),
			filteredLinks() {
				return this.authUser.profileId === 2 ? this.links.filter(item => item.link.name === 'Vending') : this.links;
				//return this.profileId === 2 ? this.links.filter(item => item.link.name === 'Vending') : this.links;
			}
		},

		data() {
			return {
				profileId: 2,
				links: [
					
					{
						condition: 'comercio',
						link: {
							name: 'Dashboard',
							icon: 'ico-sidebar ico_dashboard',
							path: '/dashboard',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Vending',
							icon: 'ico-sidebar ico_comercio',
							path: '/listadoTickets',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Clientes',
							icon: 'ico-sidebar ico_user',
							path: '/listadoClientes',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Planes',
							icon: 'ico-sidebar ico_price_tag',
							path: '/listadoPlanes',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Licencias',
							icon: 'ico-sidebar ico_license',
							path: '/listadoLicencias',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Partidos',
							icon: 'ico-sidebar ico_tennis',
							path: '/listadoPartidos',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Listado de mantenimiento',
							icon: 'ico-sidebar ni ni-settings',
							path: '/listadoMantenimiento',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Marcas',
							icon: 'ico-sidebar ni ni-tag',
							path: '/listadoMarcas',
						},
					},
					{
						condition: 'comercio',
						link: {
							name: 'Gestión de usuarios',
							icon: 'ico-sidebar ico_user2',
							path: '/listadoUsuarios',
						},
					},
					
				],
			};
		},

		methods: {
			showLink(condition) {
				let retorno;
				if (condition === 'comercio') {
					retorno = true;
				}
				return retorno;
			},
		},
	};
</script>
<style scoped>
	.W90 {
		width: 90%;
	}

	.ml5 {
		margin-left: 5%;
	}
</style>
