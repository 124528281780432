<template>
  <button class="mb-0 text-sm font-weight-bold btn bgRed" @click="goScanVending">
    <span class="button-text"><b>MANTENIMIENTO VENDING</b></span>
  </button>
</template>
<script>
export default {
  methods: {
    goScanVending() {
      this.$router.push({ name: 'scanVending' });
    }
  }
};
</script>
<style scoped>
.bgRed{
  background-color: #e0523d !important;
  color: white !important;
}
.button-icon {
  max-width: 20px;
  filter: invert(100%);
}
.button-text {
  margin-left: 8px; /* Ajusta el valor según el espacio deseado */
}

@media (max-width: 768px) { 
  .button-text {
    display: none;
  }
}
</style>
